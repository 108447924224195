<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Edit Tipe Pemohon",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Edit Tipe Pemohon",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Tipe Pemohon",
                        href: "/master/tipe-pemohon",
                    },
                    {
                        text: "Edit Tipe Pemohon",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                mtp_nama: null,
                mtp_id: this.$route.params.mtp_id,
            };
        },
        mounted() {
            // Load data awal banget
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/tipe-pemohon",
                params:{
                    mtp_id: self.mtp_id
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if(response_data.meta.code == 200){
                    var data_edit = response_data_fix.data[0];
                    self.mtp_nama = data_edit.mtp_nama;
                    Swal.close();
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});

                var config_update_data = {
                    method: "put",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/tipe-pemohon",
                    data: {
                        mtp_nama: self.mtp_nama,
                        mtp_id: self.mtp_id
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                }

                axios(config_update_data).then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master tipe pemohon segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-tipe-pemohon" });
                        }
                    });
                }).catch(function (error) {
                    self.axiosCatchError = error.response.data.data
                    Swal.close();
                });
			},
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">

                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Tipe Pemohon" label-for="formrow-nama-tipe-pemohon-input">
                                        <b-form-input id="formrow-nama-tipe-pemohon-input" placeholder="Masukkan Nama Tipe Pemohon..." type="text" v-model="mtp_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>              
                </div>
            </div>
        </div>
    </Layout>
</template>
